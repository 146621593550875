import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import { graphql } from "gatsby"
const LegalTermsConditions = ({ data: { allNodeTemplatePages: { edges } } }) => {
    
    const metaTag = [];
    const terms = edges[0]?.node;
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage">
                    <section className="section_bg pt-first-section pb-section">
                        {terms?.relationships?.components.length > 0 &&
                            <>
                                {terms?.relationships?.components.map(c => {
                                    const title = c?.field_title || "";
                                    const details = c?.text?.processed || "";
                                    if(title && details){
                                        return(
                                            <div className="mb-5">
                                                <div className="container">
                                                    <div className="section-head">
                                                        <h1>{title ? title : "Legal Terms Conditions"}</h1>
                                                    </div>
                                                </div>
                                                <div className="container">
                                                    <div className="content_card_box bg-white">
                                                        <div className="inner_card_content">
                                                            <div dangerouslySetInnerHTML={{ __html: details ? details : "null" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return <></>
                                    }
                                })}
                            </>
                        }
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
query legalTermsConditions {
allNodeTemplatePages(filter: {path: {alias: {regex: "/legal-terms-conditions$/"}}}) {
edges {
node {
id
title
path {
alias
}
metatag {
    attributes {
        content
        href
        name
        property
        rel
    }
}
relationships {
components: field_component_type {
__typename
...ParagraphTitleAndDescription
}
}
}
}
}
}`

export default LegalTermsConditions
